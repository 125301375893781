import React from "react";
import Container from "./container";

export default function Model(props) {

  return (
    <>
      <Container className="flex flex-wrap lg:gap-10 lg:flex-nowrap ">
        <div
          className={`flex items-center mb-20 justify-center w-full lg:w-1/2 ${props.imgPos === "right" ? "lg:order-1" : ""
            }`}>
            <img
              src={props.image}
              width="430"
              height="430"
              alt="Models"
              className="rounded-lg"
            />
        </div>

        <div
          className={`flex flex-wrap items-center w-full lg:w-1/2 ${props.imgPos === "right" ? "lg:justify-end" : ""
            }`}>
          <div>
            <div className="flex flex-col w-full mt-4">
              <h3 className="max-w-2xl mt-3 text-3xl font-bold leading-snug tracking-tight text-gray-800 lg:leading-tight lg:text-4xl dark:text-gray-200">
                {props.title}
              </h3>

              <p className="max-w-2xl py-4 text-lg leading-normal text-gray-500 lg:text-xl xl:text-xl dark:text-gray-300">
                {props.desc}
                {props.url && (
                <a
                  href={props.url}
                  className="text-lg font-semibold text-gray-500 dark:text-gray-300 lg:text-xl hover:text-purple-700"
                >
                  {props.urlText}
                </a>
                )}
              </p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

