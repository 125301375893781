import React, { createContext, useState, useContext } from 'react';

// Create a context with a default value and a placeholder for the setter function
const LocaleContext = createContext({
    locale: 'en', // Default locale
    setLocale: () => { } // Placeholder function
});

// Create a provider component
export const LocaleProvider = ({ children }) => {
    const [locale, setLocaleState] = useState('en'); // Initialize state with default locale

    const setLocale = () => {
        setLocaleState((prevLocale) => {
            return prevLocale === 'en' ? 'de' : 'en'; // Toggle between 'en' and 'de'
        });
    }

    return (
        <LocaleContext.Provider value={{ locale, setLocale }}>
            {children}
        </LocaleContext.Provider>
    );
};

// Custom hook to use the locale context
export const useLocale = () => useContext(LocaleContext);