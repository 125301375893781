import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

export default function CookieBanner() {
    const [show, setShow] = useState(true);

    const { t } = useTranslation(["base"]);

    return (
        <>
            {show && (
                <div className="fixed bottom-0 left-0 w-full z-50 bg-black text-white border-t-2 border-gray-700 text-sm px-0 py-2">

                    <div className="w-full px-4">
                        <span className="dark">
                            {t("cookie.message")} <Link to="/privacy" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">Privacy Page (en).</Link>
                        </span>
                    </div>

                    <div className="px-4 flex items-start mt-4">
                        <button
                            onClick={() => setShow(false)}
                            id="rcc-confirm-button"
                            aria-label="Accept cookies"
                            className="px-2 py-1 mr-2 bg-indigo-500 border border-indigo-500 rounded-md text-white cursor-pointer"
                        >
                            {t("cookie.button.ok")}
                        </button>
                        <button
                            onClick={() => setShow(false)}
                            id="rcc-decline-button"
                            aria-label="Decline cookies"
                            className="px-2 py-1 bg-gray-700 border border-gray-700 rounded-md text-white cursor-pointer"
                        >
                            {t("cookie.button.nok")}
                        </button>
                    </div>
                </div>


            )}
        </>
    );
}