// ThemeSwitch.tsx
import { useEffect } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { MoonIcon, SunIcon } from '@heroicons/react/24/solid'


function ThemeSwitch() {
  const [theme, setTheme] = useLocalStorage('theme', 'dark')

  useEffect(() => {
    document.body.classList.remove('light', 'dark')
    document.body.classList.add(theme)
  }, [theme])

  return (
    <div className="flex items-center">
      {theme === "dark" ? (
        <button
          onClick={() => setTheme("light")}
          className="text-gray-300 rounded-full outline-none focus:outline-none hover:text-indigo-500">
          <span className="sr-only">Light Mode</span>

          <SunIcon className="w-6 h-6" />
        </button>
      ) : (
        <button
          onClick={() => setTheme("dark")}
          className="rounded-full outline-none focus:outline-none focus-visible:ring focus-visible:ring-purple-300 focus:ring-opacity-20 hover:text-indigo-500">
          <span className="sr-only">Dark Mode</span>
          <MoonIcon className="w-6 h-6" />
        </button>
      )}
    </div>
  )
}

export default ThemeSwitch
