import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-purple-600">Oops!</h1>
        <p className="mt-4 text-lg text-gray-800">Sorry, an unexpected error has occurred.</p>
        <p className="italic text-gray-600">
          {error.statusText || error.message}
        </p>
      </div>
    </div>
  );
}