import React from "react";
import Container from "./container";


import logo from "../assets/img/logo.png";
import softwareLogoFooter from "../assets/img/swiss_made_software_footer.png";
import serviceLogoFooter from "../assets/img/swiss_digital_service_footer.png";

import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation(["base"]);  

  return (
    <div className="relative">
      <Container>
        <div className="grid max-w-screen-xl grid-cols-1 gap-10 pt-10 mx-auto mt-5 border-t border-gray-300 dark:border-trueGray-700 lg:grid-cols-5">
          <div className="lg:col-span-2">
            <div>
              {" "}
              <a
                href="/"
                className="flex items-center space-x-2 text-2xl font-medium text-indigo-500">

                <span>
                  <img
                    src={logo}
                    alt="N"
                    width="32"
                    height="32"
                    className="w-8"
                  />
                </span>
                <span>enki</span>

              </a>
            </div>

            <div className="max-w-md mt-4 text-gray-500 dark:text-gray-400">
              {t("footer.content")}
            </div>
          </div>

          <div>
            <div className="flex flex-wrap w-full">
            <a href="https://www.swissmadesoftware.org/about/swiss-digital-services.html" target="_blank" rel="noreferrer">
                <img
                  src={serviceLogoFooter}
                  alt="Swiss Digital Services"
                  className="max-h-11"
                />
              </a>
              <a href="https://www.swissmadesoftware.org/about/swiss-made-software.html" target="_blank" rel="noreferrer">
                <img
                  src={softwareLogoFooter}
                  alt="Swiss Made Software"
                  className="max-h-11"
                />
              </a>
            </div>
          </div>
          <div>
            <div className="flex flex-wrap w-full -mt-2 -ml-3 lg:ml-0">
              
            </div>
          </div>
          <div>
            <div className="text-sm text-gray-600 dark:text-gray-400">
              Impressum: <br></br>
              enki GmbH <br></br>
              Chriesimatt 12 <br></br> 
              6340 Baar - Switzerland <br></br>
            </div>
          </div>
        </div>
        <p className="mt-4 text-sm text-gray-600 dark:text-gray-400 text-center">Template made with ♥ by <a href="https://web3templates.com/">W3T</a>. <a href="https://www.freepik.com/author/fullvector">Images by fullvector on Freepik</a> © Copyright {new Date().getFullYear()} by enki GmbH</p>
      </Container>
    </div>
  );
}