import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import LanguageDetector from 'i18next-browser-languagedetector';

import BaseEN from './locales/en/base.json';
import BaseDE from './locales/de/base.json';
import LandingEN from './locales/en/landing.json';
import LandingDE from './locales/de/landing.json';
import CustomMLEN from './locales/en/customml.json';
import CustomMLDE from './locales/de/customml.json';
import ProjectEN from './locales/en/project.json';
import ProjectDE from './locales/de/project.json';
import EducationDE from './locales/de/education.json';
import EducationEN from './locales/en/education.json';
import ConsultingDE from './locales/de/consulting.json';
import ConsultingEN from './locales/en/consulting.json';

const resources = {
  en: {
    base: BaseEN,
    landing: LandingEN,
    customml: CustomMLEN,
    project: ProjectEN,
    education: EducationEN,
    consulting: ConsultingEN
  },
  de: {
    base: BaseDE,
    landing: LandingDE,
    customml: CustomMLDE,
    project: ProjectDE,
    education: EducationDE,
    consulting: ConsultingDE
  }
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: resources
  });

export default i18n;