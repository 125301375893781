import React from 'react';

const Card = ({ title, content, image }) => {
  return (
    <div className="max-w-sm rounded overflow-hidden bg-gray-100 dark:bg-gray-800 shadow-lg dark:shadow-purple-400/50">
      <img className="w-full" src={image} alt="Sunset in the mountains" />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2 text-purple-500">{title}</div>
        <p className="text-base">
          {content}
        </p>
      </div>
    </div>
  );
};

export default Card;