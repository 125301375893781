import VerticalCard from "../components/verticalCard";
import SectionTitle from "../components/sectionTitle";
import Hero from "../components/hero";
import Paragraph from "../components/paragraph";

import { useTranslation } from "react-i18next";

import consultingHero from "../assets/img/consulting_hero.png";
import consultingDataImg from "../assets/img/consulting_data.png";
import consultingModelImg from "../assets/img/consulting_model.png";
import consultingCodeImg from "../assets/img/consulting_code.png";
import mlopsOrgImg from "../assets/img/mlops_visual.png";


export default function Consulting() {
    const { t } = useTranslation(["consulting"]);


    return (
        <div>
            <Hero
                title={t("hero.title")}
                subtitle={t("hero.subtitle")}
                showButton={false}
                image={consultingHero} />
            <Paragraph content={t("section.intro.content")} />
            <SectionTitle
                title={t("howitworks.title")} />
            <Paragraph content={t("howitworks.desc")} />
            <VerticalCard
                title={t("howitworks.bullet.1.title")}
                desc={t("howitworks.bullet.1.desc")}
                image={consultingDataImg} />
            <VerticalCard
                title={t("howitworks.bullet.2.title")}
                desc={t("howitworks.bullet.2.desc")}
                image={consultingModelImg}
                imgPos="right" />
            <VerticalCard
                title={t("howitworks.bullet.3.title")}
                desc={t("howitworks.bullet.3.desc")}
                image={consultingCodeImg} />
            <VerticalCard
                title={t("howitworks.bullet.4.title")}
                desc={t("howitworks.bullet.4.desc")}
                image={mlopsOrgImg} 
                imgPos="right"
                url="https://ml-ops.org"
                urlText="ml-ops.org"/>
        </div>
    );
}