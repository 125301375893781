import Hero from "../components/hero";
import SectionTitle from "../components/sectionTitle";

import Benefits from "../components/benefits";
import { Projects } from "../components/projects";
import Paragraph from "../components/paragraph";
import Container from "../components/container";
import React from "react";

import { useTranslation } from "react-i18next";

import {
  AcademicCapIcon,
  GlobeAltIcon,
  ArrowTrendingUpIcon,
  CloudIcon,
  BuildingOfficeIcon,
  TrophyIcon,
  EyeIcon,
  UsersIcon,
  AdjustmentsHorizontalIcon,
  SparklesIcon,
  RocketLaunchIcon
} from "@heroicons/react/24/outline";

import heroImg from "../assets/img/server.png";
import laptopImg from "../assets/img/laptop.png";
import serviceImg from "../assets/img/service.png";
import openServiceImg from "../assets/img/open_service.png";

export default function Landing() {
  const { t } = useTranslation(["landing"]);

  const custommlData = {
    title: t("customml.title"),
    desc: t("customml.desc"),
    image: serviceImg,
    bullets: [
      {
        title: t("customml.bullet.1.title"),
        desc: t("customml.bullet.1.desc"),
        icon: <UsersIcon />
      },
      {
        title: t("customml.bullet.2.title"),
        desc: t("customml.bullet.2.desc"),
        icon: <AdjustmentsHorizontalIcon />
      },
      {
        title: t("customml.bullet.3.title"),
        desc: t("customml.bullet.3.desc"),
        icon: <CloudIcon />
      },
      {
        title: t("customml.bullet.4.title"),
        desc: t("customml.bullet.4.desc"),
        icon: <EyeIcon />
      }
    ]
  }

  const consultingData = {
    title: t("consulting.title"),
    desc: t("consulting.desc"),
    image: openServiceImg,
    bullets: [
      {
        title: t("consulting.bullet.1.title"),
        desc: t("consulting.bullet.1.desc"),
        icon: <GlobeAltIcon />,
      },
      {
        title: t("consulting.bullet.2.title"),
        desc: t("consulting.bullet.2.desc"),
        icon: <ArrowTrendingUpIcon />,
      },
      {
        title: t("consulting.bullet.3.title"),
        desc: t("consulting.bullet.3.desc"),
        icon: <RocketLaunchIcon />,
      },
      {
        title: t("consulting.bullet.4.title"),
        desc: t("consulting.bullet.4.desc"),
        icon: <SparklesIcon />,
      }
    ]
  }

  const educationData = {
    title: t("education.title"),
    desc: t("education.desc"),
    image: laptopImg,
    bullets: [
      {
        title: t("education.bullet.1.title"),
        desc: t("education.bullet.1.desc"),
        icon: <AcademicCapIcon />,
      },
      {
        title: t("education.bullet.2.title"),
        desc: t("education.bullet.2.desc"),
        icon: <BuildingOfficeIcon />,
      },
      {
        title: t("education.bullet.3.title"),
        desc: t("education.bullet.3.desc"),
        icon: <TrophyIcon />,
      },
    ]
  }


  return (
    <>
      <Hero 
        title={t("hero.title")}
        subtitle={t("hero.subtitle")}
        buttonText={t("hero.button")}
        showButton={true}
        image={heroImg} />
      <SectionTitle
        pretitle={t("section.vision.pretitle")}
        title={t("section.vision.title")}>
      </SectionTitle>
      <Paragraph content={t("section.vision.content")} />
      <SectionTitle
        pretitle={t("section.service.pretitle")}
        title={t("section.service.title")} />
      <Benefits data={custommlData} />
      <Benefits imgPos="right" data={consultingData} />
      <Benefits data={educationData} />
      <SectionTitle
        pretitle={t("section.project.pretitle")}
        title={t("section.project.title")} />
      <Projects />
      <SectionTitle
        pretitle={t("section.aboutus.pretitle")}
        title={t("section.aboutus.title")} />
      <Paragraph content={t("section.aboutus.content")} />
      <SectionTitle title={t("section.more.title")} />
      <Container className="flex flex-wrap justify-center">
        <a
          href={`mailto:info@enki.swiss?subject=${t("mailto.subject")}`}
          target="_blank"
          rel="noopener noreferrer"
          className="px-8 py-4 text-lg font-medium text-center text-white bg-indigo-500 rounded-md ">
          {t("mailto.button.text")}
        </a>
      </Container>
    </>
  );
}
