import VerticalCard from "../components/verticalCard";
import SectionTitle from "../components/sectionTitle";
import Hero from "../components/hero";
import Paragraph from "../components/paragraph";

import { useTranslation } from "react-i18next";

import emmbedingImg from "../assets/img/embedding.png"
import stockModelImg from "../assets/img/stock_model.png";
import fineTunedModelImg from "../assets/img/fine_tuned_model.png"
import aiHeroImg from "../assets/img/ai_hero2.png";
import laptopImg from "../assets/img/laptop.png";

export default function CustomML() {
    const { t } = useTranslation(["customml"]);


    return (
        <div>
            <Hero
                title={t("hero.title")}
                subtitle={t("hero.subtitle")}
                showButton={false}
                image={aiHeroImg} />
            <Paragraph content={t("section.intro.content")} />
            <SectionTitle
                title={t("howitworks.title")} />
            <Paragraph content={t("howitworks.desc")} />
            <VerticalCard
                title={t("howitworks.bullet.1.title")}
                desc={t("howitworks.bullet.1.desc")}
                image={laptopImg} />
            <VerticalCard
                title={t("howitworks.bullet.2.title")}
                desc={t("howitworks.bullet.2.desc")}
                image={emmbedingImg}
                imgPos="right" />
            <VerticalCard
                title={t("howitworks.bullet.3.title")}
                desc={t("howitworks.bullet.3.desc")}
                image={stockModelImg} />
            <VerticalCard
                title={t("howitworks.bullet.4.title")}
                desc={t("howitworks.bullet.4.desc")}
                image={fineTunedModelImg} 
                imgPos="right"/>
        </div>
    );
}