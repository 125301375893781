import React from 'react';
import * as ReactDOM from "react-dom/client";
import './globals.css';
import Landing from './routes/landing';
import Root from './routes/root';
import CustomML from './routes/customml';
import Consulting from './routes/consulting';
import Education from './routes/education';
import ErrorPage from './routes/error-page';
import Privacy from './routes/privacy';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// import i18n (needs to be bundled ;))
import './i18n';

import { LocaleProvider } from './context/localeContext';

const router = createBrowserRouter([
  {
    path: "/",
    element: < Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Landing/>,
      },
      {
        path: "customml",
        element: <CustomML />,
      },
      {
        path: "consulting",
        element: <Consulting />,
      },
      {
        path: "education",
        element: <Education />,
      },
      {
        path: "privacy",
        element: <Privacy />,
      }
    ]
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <LocaleProvider>
      <RouterProvider router={router} />
    </LocaleProvider>
  </React.StrictMode>
);

reportWebVitals();