// @ts-nocheck
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import CookieBanner from "../components/cookieBanner";
import ScrollToHashElement from "../components/scrollToHash";
import React, { useEffect } from "react";

import { Helmet } from 'react-helmet';

import { Outlet } from "react-router-dom";


export default function Root() {
    useEffect(() => {
        if (process.env.NODE_ENV === "production") {
            const script = document.createElement("script");
            script.id = "ms-clarity";
            script.async = true;
            script.src = "https://www.clarity.ms/tag/iq2efcve2y";
            script.text = `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "iq2efcve2y");`;
            document.body.appendChild(script);
        }
    }, []);

    return (
        <div className="dark:text-gray-100">
            <Helmet>
                <title>enki</title>
                <meta name="description" content="Let us take care of your ML" />
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <ScrollToHashElement />

            <Navbar />
            <Outlet className="app transition-colors duration-500 ease-in-out" />
            <Footer/>
            <CookieBanner />
        </div>
    );
}
