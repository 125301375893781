import VerticalCard from "../components/verticalCard";
import SectionTitle from "../components/sectionTitle";
import Hero from "../components/hero";
import Paragraph from "../components/paragraph";
import Container from "../components/container";

import { useTranslation } from "react-i18next";

import heroImg from "../assets/img/education_hero.png";
import overviewImg from "../assets/img/education_overview.png";
import devImg from "../assets/img/education_dev.png";
import managementImg from "../assets/img/education_management.png";

export default function Education() {
    const { t } = useTranslation(["education"]);


    return (
        <div>
            <Hero
                title={t("hero.title")}
                subtitle={t("hero.subtitle")}
                showButton={false}
                image={heroImg} />
            <Paragraph content={t("section.intro.content")} />
            <SectionTitle
                title={t("howitworks.title")} />
            <VerticalCard
                title={t("howitworks.bullet.1.title")}
                desc={t("howitworks.bullet.1.desc")}
                image={overviewImg}
                imgPos="right" />
            <VerticalCard
                title={t("howitworks.bullet.2.title")}
                desc={t("howitworks.bullet.2.desc")}
                image={devImg} />
            <VerticalCard
                title={t("howitworks.bullet.3.title")}
                desc={t("howitworks.bullet.3.desc")}
                image={managementImg}
                imgPos="right" />
            <Container className="flex flex-wrap justify-center">
                <a
                    href={`mailto:info@enki.swiss?subject=${t("mailto.subject")}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="px-8 py-4 text-lg font-medium text-center text-white bg-indigo-500 rounded-md ">
                    {t("mailto.button.text")}
                </a>
            </Container>
        </div>
    );
}