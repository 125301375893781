import React from "react";
import { useTranslation } from "react-i18next";
import Container from "./container";
import Card from "./card";

import project1Img from "../assets/img/project_generic_4.jpg";
import project2Img from "../assets/img/project_generic_2.jpg";
import project3Img from "../assets/img/project_generic_5.jpg";
import project4Img from "../assets/img/project_generic_1.jpg";
import project5Img from "../assets/img/project_generic_3.jpg";

export const Projects = () => {
    const { t } = useTranslation(["project"]);

    const projects = [
        {
            title: t("project1.title"),
            content: t("project1.desc"),
            image: project1Img,
        },
        {
            title: t("project2.title"),
            content: t("project2.desc"),
            image:project2Img,
        },
        {
            title: t("project3.title"),
            content: t("project3.desc"),
            image: project3Img,
        },
        {
            title: t("project4.title"),
            content: t("project4.desc"),
            image: project4Img,
        },
        {
            title: t("project5.title"),
            content: t("project5.desc"),
            image: project5Img,
        }
    ];

    return (
        <Container>
            <div className="flex overflow-x-auto snap-x snap-mandatory space-x-6 w-full">
                {projects.map((project, index) => (
                    <div key={index} className="snap-center shrink-0 mb-4">
                        <Card title={project.title} content={project.content} image={project.image} />
                    </div>
                ))}
            </div>
        </Container>
    );
};