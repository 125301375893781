import React from "react";
import Container from "./container";

export default function Paragraph(props) {
    return (
        <>
            <Container className="flex w-full flex-col mt-4 items-center justify-center text-center">
                <p className="max-w-7xl py-4 text-lg leading-normal text-gray-500 lg:text-xl xl:text-xl dark:text-gray-300">
                    {props.content}
                </p>
            </Container>
        </>
    );
}
