import Container from "../components/container";

export default function Privacy() {

  return (
    <>
      <Container>
        <div className="max-w-screen-xl mx-auto">
          <div className="text-4xl mb-2">Privacy Policy</div>
          <p>Last updated: 06.11.2023</p>
        </div>
      </Container>
      <Container>
        <div className="max-w-screen-xl mx-auto">
          <div className="text-2xl mb-2">Introduction</div>
          <div>
            At enki, we take your privacy seriously. This Privacy Policy explains our practices regarding the collection, use, and sharing of your personal information and how we protect your privacy. We also want to inform you that we use Microsoft Clarity for analytics, and this tool may collect certain data to help us improve our website&apos;s performance and user experience.
          </div>
        </div>
      </Container>
      <Container>
        <div className="max-w-screen-xl mx-auto">
          <div className="text-2xl mb-2">Information We Collect</div>
          <div className="mb-2">Personal Information:</div>
          <div>
            We may collect personal information such as your name, email address, and other contact details when you voluntarily provide it to us through forms or when you contact us.
          </div>
          <br></br>
          <div className="mb-2">Non-Personal Information:</div>
          <div>
            We may collect non-personal information, such as browser type, IP address, operating system, and the web pages you visit on our site. This information helps us understand how our website is used and how to improve it.
          </div>
        </div>
      </Container>
      <Container>
        <div className="max-w-screen-xl mx-auto">
          <div className="text-2xl mb-2">How We Use Your Information</div>
          <div className="mb-2">
            We use the information we collect for the following purposes:
          </div>
          <ul className="list-disc list-inside">
            <li>To respond to your inquiries and requests.</li>
            <li>To improve and optimize our website for a better user experience.</li>
            <li>To send you newsletters or updates if you have subscribed to them.</li>
            <li>To meet legal and regulatory requirements.</li>
          </ul>

        </div>
      </Container>
      <Container>
        <div className="max-w-screen-xl mx-auto">
          <div className="text-2xl mb-2">Use of Microsoft Clarity</div>
          <div>
            We use Microsoft Clarity for analytics to gain insights into how users interact with our website. Microsoft Clarity may collect data related to your interactions with our site, including mouse movements, clicks, scrolling, and user behavior. This data helps us make informed decisions to enhance our website&apos;s usability and performance.

            Microsoft Clarity does not collect personal information, such as names, email addresses, or other identifiable details. We use the information obtained from Microsoft Clarity solely for website improvement purposes.      </div>
        </div>
      </Container>
      <Container>
        <div className="max-w-screen-xl mx-auto">
          <div className="text-2xl mb-2">Third-Party Disclosure</div>
          <div>
            We do not sell, trade, or otherwise transfer your personal information to third parties without your explicit consent, except for trusted third parties who assist us in operating our website, conducting our business, or servicing you.
          </div>
        </div>
      </Container>
      <Container>
        <div className="max-w-screen-xl mx-auto">
          <div className="text-2xl mb-2">Security</div>
          <div>
            We take reasonable precautions to protect your personal information from loss, misuse, and unauthorized access. While we strive to protect your personal information, no method of transmission over the internet, or method of electronic storage, is completely secure, and we cannot guarantee its absolute security.

          </div>
        </div>
      </Container>
      <Container>
        <div className="max-w-screen-xl mx-auto">
          <div className="text-2xl mb-2">Your Choices</div>
          <div>
            You can choose to disable cookies in your web browser, which may prevent data collection by Microsoft Clarity. Additionally, you have the right to opt out of any marketing communications you receive from us.

          </div>
        </div>
      </Container>

      <Container>
        <div className="max-w-screen-xl mx-auto">
          <div className="text-2xl mb-2">Changes to this Privacy Policy</div>
          <div>
            We may update our Privacy Policy from time to time, and any changes will be posted on this page. Your continued use of our website after such changes signify your acceptance of the new terms.

          </div>
        </div>
      </Container>
      <Container>
        <div className="max-w-screen-xl mx-auto">
          <div className="text-2xl mb-2">Contact Us</div>
          <div>
            If you have any questions about this Privacy Policy or the use of Microsoft Clarity for analytics, please contact us at <a href="mailto:info@enki.swiss">info@enki.swiss</a>.

            By using our website, you consent to our Privacy Policy and the use of Microsoft Clarity as described herein.
          </div>
        </div>
      </Container>
    </>
  );
}